@import "../../Constants/colors";

.wrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  background: #fff;

  @media only screen and (max-width: 1000px) {
    min-height: calc(100vh - 64px);
  }
}

.wrapLoading {
  filter: blur(2px);
  pointer-events: none;
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  //@media only screen and (max-width: 1000px) {
  //  min-height: calc(100vh - 64px - 96px);
  //}
}

.bottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  padding: 38px 48px;

  &:before {
    content: '';
    position: absolute;
    top: 11px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  }

  @media only screen and (max-width: 1000px) {
    height: auto;
    padding: 24px;
  }
}

.bottomHide {
  display: none;
}

.bottomCornerWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
}

.bottomCorner {
  position: relative;
  display: block;
  margin-left: 35px;
  width: 30px;
  height: 20px;
}

.bottomCornerLine {
  position: absolute;
  top: 0;
  height: 20px;
  border-top: 1px solid #E7E7E7;
  background-color: #fff;
}

.bottomCornerLineLeft {
  left: 0;
  width: 35px;
}

.bottomCornerLineRight {
  left: 35px + 30px;
  right: 0;
}

.helpWrap {
  position: relative;
  display: flex;
  align-items: center;
}

.description {
  margin: 0 0 0 13px;
  font-size: 12px;
  line-height: 18px;
  color: $fontBlack;
}

.buttonsWrap {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    flex: 1;
  }
}

.buttonsWrapWithContent {
  width: 321px;
  justify-content: space-between;
}

.buttonCancel,
.buttonSubmit {
  width: auto;
  padding: 0 20px;

  @media only screen and (max-width: 1000px) {
    flex: 1;
  }
}

.buttonSubmit {
  margin-left: 16px;
}

.buttonSubmitSolo {
  margin-left: 0;

  @media only screen and (max-width: 1000px) {
    flex: 1;
  }
}
