.wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding-top: 64px;
}

.wrapFullVideo {
  padding-top: 0;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 64px;
  padding-left: 66px;
  box-shadow: 0 0 5px -2px rgba(#121413, 0.25), 0 2px 4px -4px rgba(#121413, 0.15);
  background-color: #fff;
  z-index: 10;
}

.logo {
  position: absolute;
  top: -2px;
  left: 23px;
  width: 41px;
  height: 43px;
}

.disableEl {
  display: none;
}
