.video {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100vh - 64px);
  background: #8f949c;
}

.videoFull {
  height: 100vh;
}

.chat {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 30%;
  height: calc(100vh - 64px);
  box-shadow: 0 0 5px -2px rgba(#121413, 0.25), 0 2px 4px -4px rgba(#121413, 0.15);
  background-color: #fff;
}

.disableEl {
  display: none;
}
