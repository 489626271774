.wrap {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  height: 64px;
  //border-bottom: 1px solid rgba(#121413, 0.15);
  box-shadow: 0 0 5px -2px rgba(#121413, 0.25), 0 2px 4px -4px rgba(#121413, 0.15);
}

.headerTitle {
  position: relative;
  margin: 0;
  font-weight: 500;
}

.messages {
  position: relative;
  overflow-y: scroll;
  display: block;
  flex-grow: 1;
}

.message {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
}

.messageFrom {
  justify-content: flex-start;
}

.messageFor {
  justify-content: flex-end;
}

.messageText {
  position: relative;
  display: block;
  margin: 20px;
  padding: 16px;
  border: 1px solid rgba(#121413, 0.1);
  border-radius: 5px;
  font-size: 15px;
  max-width: 80%;
  color: #000;
}

.footer {
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding: 16px;
  min-height: 82px;
  //border-top: 1px solid rgba(#121413, 0.15);
  box-shadow: 0 0 5px -1px rgba(#121413, 0.25), 0 2px 4px -2px rgba(#121413, 0.15);
}

.input {
  box-sizing: border-box;
  height: 50px;
  flex: 1;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 20px;
  border: 1px solid rgba(#121413, 0.1);
}
