@import "Constants/colors.scss";

.anticon {
  display: none !important;
}

@-webkit-keyframes autofill {
  to {
    background: #fff;
    color: $fontBlack;
  }
}

/* input */

form textarea.ant-input {
  margin-bottom: 0;
  resize: none;
}

.ant-form-item-control {
  line-height: 34px;
}

.ant-input,
.ant-select-selection,
.ant-input-number-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  border-color: rgba($tomeBlack, 0.16);
  border-radius: 4px;
  color: $fontBlack;

  &:-webkit-autofill {
    -webkit-text-fill-color: #000;
    box-shadow: 0 0 0 1000px #fff inset;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
  }
}

.ant-input[disabled],
.ant-input-number-input[disabled],
.ant-select-selection[disabled],
.ant-input[disabled]::-webkit-input-placeholder,
.ant-input-number-input[disabled]::-webkit-input-placeholder,
.ant-select-selection[disabled]::-webkit-input-placeholder {
  color: rgba($fontBlack, 0.25);
}

.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover,
.ant-input:-webkit-autofill:focus,
.ant-input:-webkit-autofill:active,
.ant-input-number-input:-webkit-autofill,
.ant-input-number-input:-webkit-autofill:hover,
.ant-input-number-input:-webkit-autofill:focus,
.ant-input-number-input:-webkit-autofill:active {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.ant-input::-webkit-input-placeholder,
.ant-select-selection::-webkit-input-placeholder,
.ant-input-number::-webkit-input-placeholder {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: rgba($tomeBlack, 0.56);
}

.ant-input,
.ant-input-number .ant-input-number-input {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.ant-input-number { height: 40px }

.ant-input-sm,
.ant-input-number-sm .ant-input-number-input {
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;

  @media only screen and (max-width: 1000px) {
    height: 48px;
    font-size: 15px;
  }
}
.ant-input-number-sm {
  height: 34px;
  @media only screen and (max-width: 1000px) {
    height: 48px;
  }
}

.ant-input-lg,
.ant-input-number-lg .ant-input-number-input {
  height: 44px;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.ant-input-number-lg { height: 44px }

.short-input .ant-form-item-children-icon { // использовать на FormItem
  display: none !important;
}

.ant-input:hover,
.ant-input-number:hover,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $inputYellow;
}

.ant-input-affix-wrapper {
  .ant-input:not(:last-child) {
    padding-right: 25px;
  }
  .ant-input-lg:not(:last-child) {
    padding-right: 35px;
  }
  .ant-input-suffix {
    left: auto !important;
  }
}

.ant-input-affix-wrapper-auto {
  &.ant-input {
    background-color: #fbfbfb;
    &:hover {
      border-color: rgba(193, 193, 193, 0.78) !important;
    }
    &:focus {
      border-color: rgba(193, 193, 193, 0.78) !important;
      box-shadow: inset 0 1px 1px 1px #f9f9f8, 0 0 0 3px rgba(197, 197, 197, 0.43) !important;
    }
  }
}

.ant-input:focus,
.ant-input-number-focused,
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: rgba(239, 202, 0, 0.78);
  box-shadow: inset 0 1px 1px 1px #f9f9f8, 0 0 0 3px rgba(249, 207, 0, 0.43);

  &:-webkit-autofill {
    box-shadow: inset 0 0 0 1000px #fff, 0 0 0 3px rgba(249, 207, 0, 0.43);
  }
}

.ant-form-item {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0;
}

.ant-form-item-children {
  display: block;
}

.ant-form-item-children-icon {
  display: none;
}

.has-success.has-feedback .ant-form-item-children:after {
  content: '';
  position: absolute;
  top: 20px;
  right: 16px;
  height: 7px;
  width: 7px;
  border-radius: 3.5px;
  background: $pointGreen;
  margin: -3.5px 0 0;
}

.has-error {
  * {
    color: $warningRed;
  }

  .ant-input:focus {
    box-shadow: inset 0 1px 1px 0.5px #f8f8f7, 0 0 0 3px rgba($warningRed, 0.2);
  }

  .ant-input-affix-wrapper {
    .ant-input:focus {
      box-shadow: inset 0 1px 1px 0.5px #f8f8f7, 0 0 0 3px rgba($warningRed, 0.2);
    }
  }

  .ant-input:not(.ant-input-disabled) {
    padding-right: 11px;
    border-color: $warningRed;
    color: $warningRed;
  }

  .ant-input::-webkit-input-placeholder,
  .ant-select-selection__placeholder {
    color: $warningRed !important;
  }

  &.has-feedback {
    .ant-form-item-children:after {
      content: '';
    }
    .ant-form-item-children-icon {
      //display: flex;
      //align-items: center;
      //justify-content: flex-end;
      //top: 1px;
      //bottom: 1px;
      //right: 1px;
      //margin-top: 0;
      //height: auto;
      //border-radius: 4px;
      //background-color: #fff;
      //z-index: 3;
      //animation: none;
      //
      //&:before {
      //  content: '';
      //  position: relative;
      //  display: block;
      //  width: 6px;
      //  height: 6px;
      //  margin-right: 13px;
      //  border-radius: 3px;
      //  background-color: $tomeRed;
      //}
    }
  }
}

.ant-input-group-addon {
  width: 40px;
  background: #fff;
}

.operation_refund_input {
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 45px;
  }
}

.card-csc-input {
  .has-error.has-feedback {
    .ant-input:not(.ant-input-disabled) {
      padding-right: 20px;
    }
    .ant-form-item-children-icon {
      width: 20px;

      &:before {
        margin-right: 9px;
      }
    }
  }
}

/* input end */
/* popover */

.ant-popover.ant-popover-placement-bottomRight {
  padding: 0;
}

.ant-popover.ant-popover-placement-bottomRight .ant-popover-arrow {
  display: none;
}

.ant-popover.ant-popover-placement-bottomRight .ant-popover-inner {
  background: none;
  box-shadow: none;
}

.ant-popover.ant-popover-placement-bottomRight .ant-popover-inner-content {
  padding: 0;
}

/* popover end */
/* menu */

.ant-menu {
  font-family: 'Roboto', sans-serif;
}

.ant-menu-horizontal {
  height: 64px;
  border: none;
  line-height: 64px;
  background: none;
}

.ant-menu-horizontal > .ant-menu-item {
  margin-right: 20px;
  border: 0;
  top: 0;
}

.ant-menu-horizontal > .ant-menu-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3.5px;
  border-radius: 1px;
  background-color: #F8CA00;
  background-image: linear-gradient(-45deg, #fcd531, #4ce9f5);
  opacity: 0;
  transition: opacity 100ms;
}

.ant-menu-horizontal > .ant-menu-submenu {
  margin-right: 30px;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  padding: 0;
  margin-left: 20px;
  height: 64px;
  border-bottom: none;
  font-size: 14px;
}

.ant-menu-submenu > .ant-menu-submenu-title {
  height: 36px;
  margin-left: -20px;
  margin-right: -30px;
  padding-left: 15px;
  padding-right: 31px;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  line-height: 34px;
}

.ant-menu-sub > .ant-menu-item-active:hover {
  background-color: rgba($tomeBlack, 0.04);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba($tomeBlack, 0.04);
}

.ant-menu-item-selected {
  pointer-events: none;
}

.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: none;
  border-top: 0;
  color: $fontBlack !important;
}

.ant-menu-horizontal > .ant-menu-item-open:before,
.ant-menu-horizontal > .ant-menu-item-selected:before,
.ant-menu-horizontal > .ant-menu-submenu-selected:before {
  opacity: 1;
}

.ant-menu-item,
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu,
.ant-menu-submenu-title,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $fontBlack !important;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-submenu-open {
  border-bottom: none;

  color: rgba($tomeBlack, 0.48) !important;
}

.ant-menu-horizontal > .ant-menu-item-selected > a,
.ant-menu-horizontal > .ant-menu-item:hover > a,
.ant-menu-horizontal > .ant-menu-item > a {
  color: $fontBlack;
}

.ant-menu-submenu {
  display: flex;
  align-items: center;
}

.ant-menu-submenu-title {
  height: 61px;
}

.ant-menu-submenu-open > .ant-menu-submenu-title {
  color: rgba($tomeBlack, 0.48);
}

.ant-menu-submenu-open.ant-menu-submenu-horizontal > .ant-menu-submenu-title svg {
  margin-top: -2px;
  transform: rotateX(180deg);
  stroke: rgba($tomeBlack, 0.48);
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item:not(:last-child) {
  margin-bottom: 1.5px;
}

.ant-menu-sub > .ant-menu-item {
  margin: 0;
  height: 32px;
  line-height: 32px;
}

ul[id$="control$Menu"].ant-menu,
ul[id$="payments$Menu"].ant-menu {
  min-width: auto;
  margin-top: -7px;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  border: 1px solid rgba($tomeBlack, 0.16);
  border-top: none;
  box-shadow: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

ul[id$="control$Menu"].ant-menu {
  width: 131px;
}

ul[id$="payments$Menu"].ant-menu {
  width: 111px;
}

/* menu end */
/* select */

.ant-selection {
  height: 40px;
  //@media only screen and (max-width: 1000px) {
  //  height: 48px;
  //  .ant-select-selection {
  //    min-height: 48px;
  //  }
  //  .ant-select-selection--multiple .ant-select-selection__rendered {
  //    line-height: 46px;
  //  }
  //  .ant-select-selection--single .ant-select-selection__rendered {
  //    line-height: 46px;
  //  }
  //}
}

.ant-select-disabled {
  .ant-select-selection--multiple {
    &:after {
      background-image: linear-gradient(270deg, #f5f5f5 51%, rgba(#f5f5f5, 0) 98%);
    }
    .ant-select-selection__choice {
      color: $fontBlack;
    }
  }
}

.ant-selection-small {
  height: 34px;

  @media only screen and (max-width: 1000px) {
    height: 48px;
  }
}

.ant-select-selection {
  min-height: 40px;
}

.ant-select-selection--multiple {
  overflow: hidden;
  padding-bottom: 6px;

  .ant-select-search {
    position: absolute;
    top: 7px;
    left: 0;
    margin: 0 !important;
    opacity: 0;
    //display: none;
  }

  .ant-select-selection__rendered {
    min-width: 1000px;
    line-height: 35px;
  }

  .ant-select-selection__placeholder {
    color: $fontBlack;
  }

  .ant-select-selection__choice {
    padding: 0 9px;
    border: none;
    border-radius: 3px;
    line-height: 23px !important;
    background: rgba(#000, 0.05);

    &__remove {
      display: none !important;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    width: 53px;
    background-image: linear-gradient(270deg, #FFFFFF 51%, rgba(#fff, 0) 98%);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.ant-select-selection-selected-value {
  padding: 0;
}

//.ant-select ul,
//.ant-select ol {
//  min-width: 1000px;
//}

.ant-select-selection--single .ant-select-selection__rendered {
  line-height: 38px;
}

.ant-select-selection__rendered {
  margin-right: 30px;
  margin-left: 10px;
}

.ant-select-allow-clear .ant-select-selection .ant-select-selection__rendered {
  margin-right: 30px;
}

.ant-select-selection__placeholder {
  margin-left: 2px;
  margin-top: -9px;
  color: rgba(0, 1, 4, 0.48);
}

.ant-select-open .ant-select-selection__placeholder {
  color: rgba(0, 1, 4, 1);
}

.ant-select-selection > ul > li,
.ant-select-selection .ant-select-selection__rendered > ul > li {
  margin-top: 7px;
}

.ant-selection-hide-dropdown .ant-select-selection {
  border-radius: 4px !important;
}

.ant-select-selection__choice {
  padding: 0 23px 0 7px;
  border: none;
  border-radius: 4px;
  background-color: rgba(0, 1, 4, 0.04);

  .ant-select-selection__hide-in-chose {
    display: none;
  }
}

.ant-select-selection:hover {
  border-color: rgba(0, 1, 4, 0.2);
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: none;
  border-color: rgba(0, 1, 4, 0.2);
}

.ant-select-open .ant-select-selection,
.ant-select-selection:active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-select-selection__clear:before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDcgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9Ii0iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtb3BhY2l0eT0iMC44Ij4gICAgICAgIDxnIGlkPSJzdGF0dXMtZmlsbGVkLWFsbC1mbHQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNDAuMDAwMDAwLCAtMjUuMDAwMDAwKSIgZmlsbD0iIzAwMDEwNCI+ICAgICAgICAgICAgPGcgaWQ9Imxpc3Rfb3BlbiI+ICAgICAgICAgICAgICAgIDxnPiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMzcuMDAwMDAwLCAyMi4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNiw2IEw2LDIuNSBMNiwyIEw3LDIgTDcsMi41IEw3LDYgTDEwLjUsNiBMMTEsNiBMMTEsNyBMMTAuNSw3IEw3LDcgTDcsMTAuNSBMNywxMSBMNiwxMSBMNiwxMC41IEw2LDcgTDIuNSw3IEwyLDcgTDIsNiBMMi41LDYgTDYsNiBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuNTAwMDAwLCA2LjUwMDAwMCkgcm90YXRlKDQ1LjAwMDAwMCkgdHJhbnNsYXRlKC02LjUwMDAwMCwgLTYuNTAwMDAwKSAiPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
}

.ant-select-selection__clear:hover:before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDcgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9Ii0iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9InN0YXR1cy1maWxsZWQtYWxsLWZsdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0MC4wMDAwMDAsIC0yNS4wMDAwMDApIiBmaWxsPSIjREY1MjRFIj4gICAgICAgICAgICA8ZyBpZD0ibGlzdF9vcGVuIj4gICAgICAgICAgICAgICAgPGc+ICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIzNy4wMDAwMDAsIDIyLjAwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik02LDYgTDYsMi41IEw2LDIgTDcsMiBMNywyLjUgTDcsNiBMMTAuNSw2IEwxMSw2IEwxMSw3IEwxMC41LDcgTDcsNyBMNywxMC41IEw3LDExIEw2LDExIEw2LDEwLjUgTDYsNyBMMi41LDcgTDIsNyBMMiw2IEwyLjUsNiBMNiw2IFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNi41MDAwMDAsIDYuNTAwMDAwKSByb3RhdGUoNDUuMDAwMDAwKSB0cmFuc2xhdGUoLTYuNTAwMDAwLCAtNi41MDAwMDApICI+PC9wYXRoPiAgICAgICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+);
}

.ant-selection img,
.ant-selection i,
.ant-selection .ant-select-arrow {
  display: none;
}

.ant-select-selection__clear {
  top: 1px;
  right: 1px;
  width: 29px;
  height: 29px;
  padding-right: 5px;
  padding-top: 8px;
  margin: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.ant-selection__dropdown.ant-select-dropdown {
  box-shadow: none;
  border: none;
  background: none;
}

.ant-selection__dropdown > div {
  position: relative;
  top: -4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 1, 4, 0.1), 0 4px 16px 2px #fff;
  border: 1px solid rgba(0, 1, 4, 0.2);
  border-top: 0;
  border-radius: 0 0 4px 4px;
}

.ant-selection__dropdown .ant-select-dropdown-menu {
  padding-top: 4px;
  padding-bottom: 2px;
}

.ant-selection__dropdown {
  .ant-select-dropdown-menu-item {
    padding: 0 11px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    color: $fontBlack;

    @media only screen and (max-width: 1000px) {
      font-size: 15px;
    }
  }
  .ant-select-dropdown-menu-item-selected {
    font-weight: 500;
    background-color: rgba(#000104, 0.04);
  }
}

.ant-selection__dropdown .ant-select-dropdown-menu-item:hover,
.ant-selection__dropdown .ant-select-dropdown-menu-item-active {
  background-color: rgba($tomeBlack, 0.04);
}

.ant-select-sm {
  .ant-select-selection--single {
    height: 34px;
  }
  .ant-select-selection {
    min-height: 34px;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered {
    line-height: 32px;
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    line-height: 32px;
  }
  .ant-select-selection__rendered {
    margin-right: 30px;
    margin-left: 10px;
  }

  @media only screen and (max-width: 1000px) {
    .ant-select-selection {
      min-height: 48px;
    }
    .ant-select-selection--multiple .ant-select-selection__rendered {
      line-height: 46px;
    }
    .ant-select-selection--single .ant-select-selection__rendered {
      line-height: 46px;
    }
  }
}

.ant-select-lg {
  .ant-select-selection--single {
    height: 44px;
  }
  .ant-select-selection {
    min-height: 44px;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered {
    line-height: 42px;
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    line-height: 42px;
  }
  .ant-select-selection__rendered {
    margin-right: 30px;
    margin-left: 10px;
  }
}

.ant-select-dropdown.ant-select-dropdown--multiple {
  .ant-select-dropdown-menu-item {}
  .ant-select-dropdown-menu-item-selected {
    font-weight: 500;
    background-color: rgba(#000104, 0.04);

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 14px;
      width: 13px;
      height: 9px;
      margin-top: -4px;
      background: url('./img/multipleSelectCheck.svg') no-repeat center center;
    }
  }
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-duration: 0s;
}

/* select end */

/* range slider start */

.ant-calendar-range-picker-input {
  width: 33%;
  text-align: left;
}

.ant-calendar-range-picker-separator {
  min-width: 24px;
  line-height: 30px;
}

.ant-calendar-picker-clear {
  display: none;
}

.operations_filter_range,
.date_input {
  cursor: pointer;

  .ant-calendar-picker-icon {
    display: none;
  }
  .ant-calendar-range-picker-input {
    width: 74px;
    cursor: pointer;
  }

  .ant-input:not(.ant-input-disabled) {
    background-color: rgba(#FBE5D6, 0.21);

    &:active {
      background-color: rgba(222, 187, 164, 0.21);
    }
  }

  .ant-input:focus,
  &.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: rgba($tomeBlack, 0.16);
    box-shadow: none !important;
  }
}

.operations_filter_range_active {
  border-color: transparent;
  .ant-input:not(.ant-input-disabled) {
    background-color: $tomeYellow;
    border-color: transparent;

    &:active {
      background-color: $tomeYellow;
      border-color: transparent;
    }
    .ant-calendar-range-picker-separator {
      min-width: 20px;
    }
    .ant-calendar-range-picker-input {
      width: 76px;
      font-weight: 500;
    }
  }

  .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: transparent !important;
  }

  &:hover {
    .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: rgba($tomeBlack, 0.16) !important;
    }
  }
}

.date_input {
  .ant-input {
    background-color: #fff;

    &:active {
      background-color: #fff;
    }
  }
}

.operations_filter_range_picker,
.date_input_picker {
  //padding-top: 43px;

  .ant-calendar-input-wrap,
  .ant-calendar-range-middle {
    display: none;
  }

  .ant-calendar {
    top: -60px;
    left: -175px;
  }
}

.date_input_picker {
  .ant-calendar {
    top: 0;
    left: 0;
  }
  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
    display: none;
  }
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: rgba(0, 1, 4, 0.2);
}

/* range slider end */

/* calendar start */

.ant-calendar {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 0 6px 0 rgba(0,0,0,0.06);
}

.ant-calendar-picker-container {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $fontBlack;
}

.ant-calendar-range {
  .ant-calendar-body {
    border-color: rgba($tomeBlack, 0.08);
  }

  .ant-calendar-in-range-cell::before {
    top: 3px;
    bottom: 3px;
    background-color: #F6F1EE;
  }
}

.ant-calendar-range-left {
  .ant-calendar-body {
    padding-right: 12px;
  }

  .ant-calendar-prev-month-btn {
    left: 26px;
  }
  .ant-calendar-next-month-btn {
    right: 12px;
  }
}

.ant-calendar-range-right {
  .ant-calendar-body {
    padding-left: 12px;
  }

  .ant-calendar-prev-month-btn {
    left: 12px;
  }
  .ant-calendar-next-month-btn {
    right: 26px;
  }
}

.ant-calendar-header {
  height: 56px;
  line-height: 56px;

  .ant-calendar-prev-year-btn,
  .ant-calendar-next-year-btn {
    display: none;
  }

  .ant-calendar-prev-month-btn,
  .ant-calendar-next-month-btn {
    top: 15px;
    width: 28px;
    height: 28px;
    border-radius: 3px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba($tomeBlack, 0);
    transition: background-color 50ms linear;

    &:before {
      display: none;
    }

    &:hover {
      background-color: rgba($tomeBlack, 0.04);
    }
    &:active {
      background-color: rgba($tomeBlack, 0.06);
    }
  }

  .ant-calendar-prev-month-btn {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTIgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTAuMTdfdG9tZV93YWxsZXRfb3BlcmF0aW9uc18xOTIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzYwLjAwMDAwMCwgLTI3Mi4wMDAwMDApIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMS41ODg4ODg4OSI+CiAgICAgICAgICAgIDxnIGlkPSJjYWxlbmRhci1vcGVuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgLTAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iYXJyb3dfMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzY2LjUwMDAwMCwgMjc3LjUwMDAwMCkgc2NhbGUoLTEsIDEpIHRyYW5zbGF0ZSgtNzY2LjUwMDAwMCwgLTI3Ny41MDAwMDApIHRyYW5zbGF0ZSg3NjEuMDAwMDAwLCAyNzIuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoLTMiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHBvaW50cz0iNi4yODY2NjA2OCAwLjc0MjkwMzM1MyAxMC45NjMyNTkgNS40MTk1MDE2OCA2LjIzNzkwNzg3IDEwLjE0NDg1MjgiPjwvcG9seWxpbmU+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTEwLjc1MDc1MDIsNS40NTQ4NTIzMyBMMy40MDAzNTAyNGUtMTMsNS40NTQ4NTIzMyIgaWQ9IlBhdGgtNCI+PC9wYXRoPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
  }
  .ant-calendar-next-month-btn {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTIgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTAuMTdfdG9tZV93YWxsZXRfb3BlcmF0aW9uc18xOTIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIzNS4wMDAwMDAsIC0yNzIuMDAwMDAwKSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjEuNTg4ODg4ODkiPgogICAgICAgICAgICA8ZyBpZD0iY2FsZW5kYXItb3BlbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIC0wLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9ImFycm93XzIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyMzUuMDAwMDAwLCAyNzIuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoLTMiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHBvaW50cz0iNi4yODY2NjA2OCAwLjc0MjkwMzM1MyAxMC45NjMyNTkgNS40MTk1MDE2OCA2LjIzNzkwNzg3IDEwLjE0NDg1MjgiPjwvcG9seWxpbmU+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTEwLjc1MDc1MDIsNS40NTQ4NTIzMyBMMy40MDAzNTAyNGUtMTMsNS40NTQ4NTIzMyIgaWQ9IlBhdGgtNCI+PC9wYXRoPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
  }
}

.ant-calendar-prev-month-btn,
.ant-calendar-next-month-btn {
  width: 56px;
  height: 56px;
  padding: 0;
  &:after {
    display: none;
  }
}

.ant-calendar-body {
  padding: 11px 26px;
}

.ant-calendar-cell {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.ant-calendar-date {
  width: 28px;
  height: 28px;
  line-height: 26px;
  color: $fontBlack;

  &:hover {
    background-color: #F6F1EE;
  }
}

.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date {
  color: rgba($tomeBlack, 0.56);
}

.ant-calendar-disabled-cell {
  cursor: default;
  .ant-calendar-date {
    background-color: rgba(0,0,0,0.02);
    color: rgba($fontBlack, 0.35);
  }
}

.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
  background-color: #9E4541;

  &:hover {
    background-color: #9E4541;
  }
}

.ant-calendar-today {
  .ant-calendar-date {
    font-weight: 500;
    color: #9E4541;
    border-color: #9E4541;
  }

  &.ant-calendar-selected-date,
  &.ant-calendar-selected-start-date,
  &.ant-calendar-selected-end-date {

    .ant-calendar-date {
      color: #fff;
    }
  }
}

/* calendar end */

/* tooltip */

$tooltipBgColor: rgba(#000, 0.8);

.input-tooltip {
  &.ant-tooltip {
    min-width: 120px;
    max-width: 375px;
  }

  .ant-tooltip-inner {
    display: inline-table;
    max-width: 220px;
    min-height: 26px;
    padding: 5px 9px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(#fff, 0.95);
    border-radius: 3px;
    background: $tooltipBgColor;
    box-shadow: 0 -2px 8px 0 rgba(#000, 0.15), inset 0 0 0 1px rgba(#000, 0.16);

    div {
      display: inline;
    }
  }

  //.ant-tooltip-inner-content {
  //  display: inline;
  //}

  .ant-tooltip-arrow {
    border: none;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &.ant-tooltip-placement-topRight {
    text-align: right;
  }

  &.ant-tooltip-placement-topRight,
  &.ant-tooltip-placement-bottomRight {
    .ant-tooltip-arrow {
      right: 9px;
    }
  }

  &.ant-tooltip-placement-topLeft,
  &.ant-tooltip-placement-bottomLeft {
    .ant-tooltip-arrow {
      left: 9px;
    }
  }

  &.ant-tooltip-placement-top,
  &.ant-tooltip-placement-topLeft,
  &.ant-tooltip-placement-topRight {
    .ant-tooltip-arrow {
      bottom: 1px;
      width: 16px;
      height: 7px;
      background-image: url('img/tooltipArrow/top.svg');
    }
  }

  &.ant-tooltip-placement-bottom,
  &.ant-tooltip-placement-bottomLeft,
  &.ant-tooltip-placement-bottomRight {
    .ant-tooltip-arrow {
      top: 1px;
      width: 16px;
      height: 7px;
      background-image: url('img/tooltipArrow/bottom.svg');
    }
  }

  &.ant-tooltip-placement-left,
  &.ant-tooltip-placement-leftTop,
  &.ant-tooltip-placement-leftBottom {
    .ant-tooltip-arrow {
      right: 1px;
      width: 7px;
      height: 17px;
      margin-top: -8.5px;
      background-image: url('img/tooltipArrow/left.svg');
    }
  }

  &.ant-tooltip-placement-right,
  &.ant-tooltip-placement-rightTop,
  &.ant-tooltip-placement-rightBottom {
    .ant-tooltip-arrow {
      left: 1px;
      width: 7px;
      height: 17px;
      margin-top: -8.5px;
      background-image: url('img/tooltipArrow/right.svg');
    }
  }
}

.input-tooltipPointless {
  pointer-events: none;
}

.input-info-tooltip {
  .ant-tooltip-inner {
    max-width: 165px;
  }

  &.ant-tooltip-placement-topRight {
    padding-bottom: 12px;
    .ant-tooltip-arrow {
      bottom: 5px;
      right: 4px;
    }
  }
}

/* tooltip end */

/* Popover */

.custom-popover {
  .ant-popover-inner {
    display: inline-table;
    max-width: 220px;
    min-height: 26px;
    padding: 5px 9px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    border-radius: 3px;
    background: $tooltipBgColor;
    box-shadow: 0 -2px 8px 0 rgba(#000, 0.15), inner 0 0 0 1px rgba(#000, 0.16);

    div {
      display: inline;
    }
  }

  .ant-popover-inner-content {
    padding: 0;
    color: rgba(#fff, 0.95);
  }

  .ant-popover-arrow {
    border: none !important;
    box-shadow: none !important;
    background-repeat: no-repeat;
    background-position: center center;
    transform: translateX(-50%) !important;
  }

  .ant-popover-arrow-content {
    background: transparent;
  }

  &.ant-popover-placement-top,
  &.ant-popover-placement-topLeft,
  &.ant-popover-placement-topRight {
    padding-bottom: 12px;
    .ant-popover-arrow {
      bottom: -7px;
      width: 16px;
      height: 7px;
      background-image: url('img/tooltipArrow/top.svg');
    }
  }

  &.ant-popover-placement-bottom,
  &.ant-popover-placement-bottomLeft,
  &.ant-popover-placement-bottomRight {
    padding-top: 12px;
    .ant-popover-arrow {
      top: -7px;
      width: 16px;
      height: 7px;
      background-image: url('img/tooltipArrow/bottom.svg');
    }
  }

  &.ant-popover-placement-left,
  &.ant-popover-placement-leftTop,
  &.ant-popover-placement-leftBottom {
    padding-right: 12px;
    .ant-popover-arrow {
      right: 9px;
      width: 7px;
      height: 17px;
      margin-top: -8.5px;
      background-image: url('img/tooltipArrow/left.svg');
    }
  }

  &.ant-popover-placement-right,
  &.ant-popover-placement-rightTop,
  &.ant-popover-placement-rightBottom {
    padding-left: 12px;
    .ant-popover-arrow {
      left: 9px;
      width: 7px;
      height: 17px;
      margin-top: -8.5px;
      background-image: url('img/tooltipArrow/right.svg');
    }
  }
}

.custom-popover_paymentSystems-last {
  .ant-popover-inner {
    max-width: 170px;
  }
}

/* Popover end */

/* notification */

.ant-notification {
  width: 326px;
  margin-right: 52px;

  @media only screen and (max-width: 1000px) {
    max-width: calc(100vw - 16px);
    width: calc(100vw - 16px);
    margin-right: 8px;
  }
}

.custom-notification {
  &.ant-notification-notice {
    overflow: visible;
    margin-bottom: 38px;
    padding: 25px 64px 25px 90px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px rgba(18,20,19,0.01), 0 2px 28px 0 rgba(0,0,0,0.13);

    @media only screen and (max-width: 1000px) {
      padding: 25px 60px 25px 24px;
    }
  }

  .ant-notification-notice-icon {
    top: 14px;
    left: 26px;
    margin: 0;
  }

  .ant-notification-notice-message {
    display: none;
    //margin: 0 0 5px 0;
    //letter-spacing: 0;
    //font-weight: 500;
    //font-size: 16px;
    //line-height: 32px;
    //color: $fontBlack;
  }

  .ant-notification-notice-description {
    margin: 0;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 22px;
    color: $fontBlack;

    @media only screen and (max-width: 1000px) {
      font-size: 15px;
      line-height: 24px;
    }
  }

  .ant-notification-notice-btn {
    margin: 0;
  }

  .ant-notification-notice-close {
    display: none;
  }

  &.custom-notification-error.ant-notification-notice {
    padding-left: 32px;
    border-left: 4px solid #EA2C3B;
  }
  &.custom-notification-success.ant-notification-notice {
    border-left: 4px solid #009323;
  }
}

/* notification end */

/* message */

.ant-message {
  z-index: 9999999;
}

.ant-message-notice {
  width: 330px;
  padding: 8px 15px;
  text-align: left;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  animation-name: MessageOut;
}

@keyframes MessageOut {
  0% {
    max-height: 150px;
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    max-height: 0;
    transform: translateY(-8px);
    opacity: 0;
  }
}

.ant-message-notice-content {
  position: relative;
  max-width: 300px;
  min-height: 26px;
  padding: 8px 12px 10px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 3px;
  background: $tooltipBgColor;
  box-shadow: 0 -2px 8px 0 rgba(#000, 0.15), inner 0 0 0 1px rgba(#000, 0.16);
  color: #fff;
  word-break: break-word;

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    left: 10px;
    width: 16px;
    height: 7px;
    background-image: url('img/tooltipArrow/bottom.svg');
  }
}

.ant-message-custom-content {
  position: relative;
  display: inline;
  width: auto;
}

/* message end */

/* modal */

.ant-modal-custom {
  padding-top: 54px;
  transform-origin: 106px 416px !important;
  .ant-modal-content {
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(#000,0.02), 0 4px 6px 0 rgba(#000,0.15);
    background-color: #fff;
  }

  .ant-modal-body {
    padding: 34px 34px 24px;
  }

  .ant-modal-confirm-title {
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $fontBlack;
  }

  .ant-modal-confirm-content {
    margin: 0 0 24px 0 !important;
    font-size: 14px;
    line-height: 22px;
    color: rgba($tomeBlack, 0.56);
  }

  .ant-modal-confirm-btns {
    margin: 0;
  }
}

.ant-modal-close {
  display: none;
}

.ant-modal-custom-custom {
  width: 450px !important;

  .ant-modal-footer {
    border-top: none;
    padding: 0;
  }
}

.ant-modal-custom-ok,
.ant-modal-custom-cancel {
  height: 38px;
  padding: 0 20px;
  border: 0;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: all 200ms !important;
  text-shadow: none;
}

.ant-modal-custom-ok {
  margin-left: 16px !important;
  background-image: linear-gradient(135deg, #ffe877, #ffec78) !important;
  background-color: $tomeYellow !important;
  color: $fontBlack !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), 0 2px 1px rgba(0,0,0, 0.03) !important;

  &:hover {
    background-color: rgba($tomeYellow, 0.9) !important;
  }
  &:active {
    background-image: linear-gradient(-45deg, #fbe05e, #f5de5c) !important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), 0 2px 1px rgba(0,0,0, 0) !important;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
  }
}

.ant-modal-custom-cancel {
  color: $fontBlack !important;
  background-color: $white !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.16), 0 2px 1px rgba(0,0,0, 0.03) !important;

  &:hover {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0,0,0, 0.03) !important;
  }

  &:active {
    background-color: rgba($tomeBlack, 0.02) !important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.16) !important;
  }

  &[click-animating-without-extra-node]:after {
    border-color: rgba($tomeBlack, 0.2) !important;
  }
}

/* modal end */

/* checkbox */

.ant-checkbox-wrapper {
  color: $fontBlack;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $tomeYellow;
    border-color: $tomeYellow;

    &:after {
      //border-width: 1px;
      border-color: #000;
    }
  }

  &:after {
    border-color: $tomeYellow;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $tomeYellow;
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border: 1px solid rgba($tomeBlack, 0.16);
  border-radius: 3px;
  background: #FFFFFF;

  &:after {
    top: 48%;
    left: 25%;
    border-color: #000;
  }
}

.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: none !important;
}

.ant-input-number-handler-up,
.ant-input-number-handler-up:active {
  background-image: url('./img/inputNumberArrows/up.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.ant-input-number-handler-down,
.ant-input-number-handler-down:active {
  background-image: url('./img/inputNumberArrows/down.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.ant-input-number-handler-up:active,
.ant-input-number-handler-down:active {
  background-color: rgba($tomeBlack, 0.02);
}

/* checkbox end */
