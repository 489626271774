.wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.myStream {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 400px;
  max-height: 400px;
}

.callerStream {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100% - 82px);
}

.callerStreamFullScreen {
  height: 100%;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 82px;
  background: rgba(#000, 0.04);
}

.controlsFullScreen {
  background: rgba(#fff, 0.04);
}

.controlButton {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  margin: 0 20px;
  padding: 0;
  border: 0;
  border-radius: 4px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  transition: all .2s;
  background-color: #fff;
  color: #000;
  box-shadow: inset 0 0 0 1px rgba(#000, 0.06), 0 2px 1px rgba(#000, 0.03);
}

.controlIco {
  max-width: 22px;
  max-height: 22px;
}

.controlIcoDisable {
  opacity: 0.2;
}